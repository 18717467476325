import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';

import 'chartjs-plugin-annotation';

import { verticalLinePlugin } from '../../components/plugins/ChartPlugins.js';
import { kernelDensityEstimation, linspace } from '../../utils/mathUtils.js';

/**
 * Renders the "MROWSelectorButtonGroup" component.
 * @param {Object} props of the page.
 * @return {jsx} The  MROWSelectorButtonGroup component.
 */
export const MROWSelector = ({ activity, setActivity }) => {
  const theme = useTheme();

  const stepTimes = {};

  ['L', 'R'].forEach((side) => {
    stepTimes[side] = [];
    activity.results.rows.forEach((row) => {
      stepTimes[side].push(...row[side].t0.values);
    });
  });

  const allStepTimes = stepTimes.R.concat(...stepTimes.L);
  const t0 = Math.min(...allStepTimes);
  const tf = Math.max(...allStepTimes);

  const noDatapoints = Math.ceil(tf - t0);

  const padTime = 0;
  const timestampsArray = linspace(t0 - padTime, tf + padTime, noDatapoints);

  const datasets = [
    {
      label: 'Step Density',
      fill: true,
      borderColor: theme.palette.info.main,
      backgroundColor: `${theme.palette.info.main}40`,
      pointRadius: 0,
      data: kernelDensityEstimation(allStepTimes, timestampsArray, 3)
    }
  ];

  /**
   * Converts a number to a string and pads it with zeros.
   * @param {number} num number to convert to padded string.
   * @param {number} size size of zero padding.
   * @return {string} the padded number string.
   */
  const pad = (num, size) => {
    const numberString = `000000000${num}`;
    return numberString.substr(-size);
  };

  const lineData = {
    labels: timestampsArray.map((data) => {
      const dateTime = new Date(data * 1000.0);
      return `${pad(dateTime.getHours(), 2)}:${pad(dateTime.getMinutes(), 2)}`;
    }),
    datasets: datasets
  };

  const yMax = Math.max(...datasets.map((ds) => ds.data).flat());

  const makeAnnotations = activity.results.rows.map((row, idx) => {
    const timeStart = Math.min(row['L'].t0.min, row['R'].t0.min);
    const timeEnd = Math.max(row['L'].t0.max, row['R'].t0.max);
    const startIdx = Math.round(
      ((timeStart - t0 + padTime - 2) / (tf - t0 + 2 * padTime)) * noDatapoints
    );
    const endIdx = Math.round(
      ((timeEnd - t0 + padTime + 2) / (tf - t0 + 2 * padTime)) * noDatapoints
    );
    return {
      type: 'box',
      xMin: startIdx,
      xMax: endIdx,
      yMin: 0,
      yMax: yMax,
      backgroundColor:
        activity.selectedRow === idx
          ? `${theme.palette.grey[400]}80`
          : `${theme.palette.grey[400]}08`,
      borderColor: `${theme.palette.grey[400]}80`,
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      onClick: () => {
        const _activity = { ...activity };
        _activity.selectedRow = idx;
        setActivity(_activity);
      }
    };
  });

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            display: true,
            drawOnChartArea: false,
            maxRotation: 0,
            minRotation: 0,
            callback: (val, idx) => (idx % 2 === 0 ? val : '')
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }
      ]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          const dLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dLabel}`;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    annotation: {
      drawTime: 'afterDatasetsDraw',
      events: ['click'],
      annotations: makeAnnotations
    }
  };

  return (
    <Box sx={{ height: '100%', width: '100%', paddingRight: 1 }}>
      <Line data={lineData} options={lineOptions} width={null} height={null} plugins={verticalLinePlugin()} />
    </Box>
  );
};

MROWSelector.propTypes = {
  activity: PropTypes.object,
  setActivity: PropTypes.func
};
