import React, {Fragment,useState} from 'react';
import { Line } from 'react-chartjs-2';
import { FaChartLine } from 'react-icons/fa';
import { TbListDetails } from "react-icons/tb";
import { useTheme } from '@emotion/react';
import { Box, ButtonGroup, Divider,Grid, Stack, Typography} from '@mui/material';
import { PropTypes } from 'prop-types';

import { verticalLinePlugin } from '../../../components/plugins/ChartPlugins';
import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { MTooltipIconButton } from '../../../components/ui/MTooltipIconButton';
import { colors } from '../../../styles/colors';
import { average, stdDev } from '../../../utils/mathUtils';

import { PARAMETER_CONFIG } from './MHRConfig';
import { MHRRepetitionCountDoughnut } from './MHRMiniPlots';

import "../../../index.css"


export const MHRSetResults = ({ idx, set }) => {
  const [view, setView] = useState('angle')

  const extractSetValues = (stat, side) => {
    const values = []
    for (const raise of set.raises[side]) {
      if (stat === 'fraction') {
        const lower_fraction = 1 - raise['raise_fraction']- raise['hold_fraction']
        values.push([raise['raise_fraction'], raise['hold_fraction'], lower_fraction])
        continue
      }

      values.push(raise[stat])
    }
    return values
  }

  const detailsBoxColor = `${colors.lightGrey2}60`

  const onClickToggleButtonClick = (event) => {
    const buttonValue = event.currentTarget.value
  
    if (buttonValue === view) {
      return
    }

    setView(buttonValue)
  } 

  const makeGraphs = () => {
    let lg; 
    if (set.raises.L?.length > 0) {
      lg = <MHRSetResultsDetails stat={view} values={extractSetValues(view, "L")} side={"Left"}/>
    }

    let rg;
    if (set.raises.R?.length > 0) {
      rg = <MHRSetResultsDetails stat={view} values={extractSetValues(view, "R")} side={"Right"}/>
    }

    if (lg && rg) {
      return (
        <Fragment>
          <Box sx={{display: 'flex', height: 'calc((100% - 2px) / 2)', width: '100%'}}>
            {lg}
          </Box>
          <Divider orientation='horizontal' flexItem/>
          <Box sx={{display: 'flex', height: 'calc((100% - 2px) / 2)', width: '100%'}}>
            {rg}
          </Box>
        </Fragment>   
      )
    }

    if (rg || lg) {
      return (
          <Box sx={{display: 'flex', height: '100%', width: '100%'}}>
            {lg ? lg : rg}
          </Box>
      )
    }
  }
  return (
    <Box sx={{p: 1, height: '100%', width: '100%', overflow: 'hidden'}}>
      <Stack spacing={1} direction="row" sx={{ height: '100%', width: '100%' }}>
        <Box sx={{display: 'flex', height: "100%", width: 200, alignItems: 'center' }}>
          <MStatisticsCard title={`Set #${idx + 1}: Details`} icon={TbListDetails} paperColor={detailsBoxColor}>
            <Box sx={{ display: 'flex', width: "100%", height: '100%', justifyContent: 'center', flexDirection: 'column'}}>
              <Box sx={{display: 'flex', justifyContent: 'center', width: "100%", flexDirection: 'column' }}>
                <Typography variant='caption' align='center'>
                  Type
                </Typography>
                <Typography align='center'>
                  {set.stype.charAt(0).toUpperCase() + set.stype.slice(1)}
                </Typography>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center', height: '30%', width: "100%", flexDirection: 'column', mt: 4}}>
                <Typography variant='caption' align='center'>
                  Rep. Count
                </Typography>
                <MHRRepetitionCountDoughnut sets={[set]}/>
              </Box>
            </Box>
          </MStatisticsCard>
        </Box>
        <Box sx={{ display: 'flex', height: '100%', width: 'calc(100% - 200px)' }}>
          <MStatisticsCard title={`Set #${idx + 1}:  Results`} icon={FaChartLine}>
            <Box sx={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', p: 1 }}>
              <ButtonGroup color="primary" orientation='vertical' sx={{width: 50 }}>
                <MTooltipIconButton
                  buttonValue="angle"
                  title="Show Raise Angle"
                  value={'angle'}
                  onClickEvent={onClickToggleButtonClick}
                  variant={view === 'angle'}
                />
                <MTooltipIconButton
                  buttonValue="duration"
                  title="Show Raise Duration"
                  value={'duration'}
                  onClickEvent={onClickToggleButtonClick}
                  variant={view === 'duration'}
                />
                <MTooltipIconButton
                  buttonValue="percent"
                  title="Show Raise Fractions"
                  value={'fraction'}
                  onClickEvent={onClickToggleButtonClick}
                  variant={view === 'fraction'}
                />
              </ButtonGroup>
              <Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 50px)', height: '100%' }}>
                {makeGraphs()}
              </Box>

            </Box>
          </MStatisticsCard>
        </Box>
      </Stack>
    </Box>
  );
};

  
MHRSetResults.propTypes = {
  idx: PropTypes.number,
  set: PropTypes.object,
}


const MHRSetResultsDetails = ({stat, values, side}) => {
  const theme = useTheme()
  
  const param = PARAMETER_CONFIG[stat]
  let color
  if (!param.color) {
    color = (side === "Left") ? theme.palette.info.main : theme.palette.info.light
  } else {
    color = param.color
  }

  
  let datasets = []
  if (stat === 'fraction') {
    datasets=[
      {
        label: param.label[0],
        data: values.map(val => val[0] * 100),
        fill: true,
        backgroundColor: `${color[0]}80`,
        pointRadius: 0,
      },
      {
        label: param.label[1],
        data: values.map(val => (1 - val[1]) * 100),
        fill: true,
        borderWidth: 2,
        backgroundColor: `${color[1]}80`,
        pointRadius: 0,
      },
      {
        label: param.label[2],
        data: values.map(() => 100),
        fill: true,
        borderWidth: 2,
        backgroundColor: `${color[2]}80`,
        pointRadius: 0,
      },
    ]
  } else {
    datasets.push(
      {
        label: param.label,
        data: values,
        fill: true,
        borderWidth: 2,
        backgroundColor: `${color}60`,
        borderColor: color
      }
    )
  }

  const chartData = {
    labels: Array.from({length: values.length}, (_, idx) => idx + 1),
    datasets: datasets,
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      intersect: false,
      mode: 'index',
      callbacks: {
        label: (item) => {
          if (stat === 'fraction') {
            return `${param.label[item.datasetIndex]}: ${(values[item.index][item.datasetIndex] * 100).toFixed(0)}${param.unit}`
          } else {
            return `${item.value}${param.unit}`
          }
        }
      } 
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: `${side} Raise No.`
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: stat === 'fraction' ? `Fraction [${param.unit}]` : `${param.label} [${param.unit}]`,
        },
        ticks: param.tickConfig,
        gridLines: {
          display: true,
        },
      }]
    },
  }

  return (
    <Box sx={{display: 'flex', height: '100%', width: '100%', flexDirection: 'column', p: 1}}>
      <Grid container justifyContent="center" sx={{height: 20, width: '100%'}}>
        {(stat === 'fraction') && (
          <>
            { datasets.map( (ds, idx) => (
              <Grid key={idx} item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography align="center" variant="caption" sx={{ fontSize: 12 }}>
                  {`${ds.label}: ${average(values.map((val) => val[idx] * 100)).toFixed(1)} ± ${stdDev(values.map((val) => val[idx] * 100)).toFixed(1)}%`}
                </Typography>
              </Grid>
            ))}
        </>
        )}
        {(stat !== 'fraction') && (
          <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography align="center" variant="caption" sx={{ fontSize: 12 }}>
            {`${param.label}: ${average(values).toFixed(2)} ± ${stdDev(values).toFixed(1)}${param.unit}`}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box sx={{display: 'flex', height: `calc(100% - 30px)`, width: '100%' }}>
        <Line data={chartData} options={chartOptions} redraw={true} plugins={[verticalLinePlugin("black")]}/>
      </Box>
    </Box>
  )
}

MHRSetResultsDetails.propTypes = {
  stat: PropTypes.string,
  values: PropTypes.array,
  side: PropTypes.string,
}
