import {HSVtoRGB, RGBtoHSV} from "../../../utils/auxFunctions"
import { linspace } from "../../../utils/mathUtils"

export const SET_DURATION = 10

export const TOE_TAPPING_PARAMETERS = [
    "duration",
    "amplitude",
    "intensity"
]


export const PARAMETER_DEFS = {
  reps: {
    label: "Taps",
    unit: " ",
    decimals: 0,
    range: [0, 80]
  },
  duration: {
    label: "Duration",
    unit: "ms",
    conversion: (values => values * 1000),
    decimals: 0,
    range: [0, 800],
    stepSize: 200
  },
  amplitude: {
    label: "Amplitude",
    unit: "°",
    decimals: 1,
    range: [0, 8]
  },
  intensity: {
    label: "Intesity",
    unit: "",
    decimals: 1,
    range: [0, 10]
  },
  frequency: {
    label: "Frequency",
    unit: "Hz",
    decimals: 1,
    range: [0, 8]
  },
}

export const FS = 100

export const RGB_COLORS = {
  Left: [0, 143, 204],
  Right: [78, 191, 255],
}

export const generateColorMap = (baseRGB, num) => {
  const result = []
  const increments = linspace(0, 0.5, num)

  const {h, s, v} = RGBtoHSV(...baseRGB)
  increments.forEach((i) => {
    const _v = (1 - i/3) * v
    const _h = (1 - i/2) * h
    const {r, g, b} = HSVtoRGB(_h, s, _v)

    result.push(`rgb(${r.toFixed(0)}, ${g.toFixed(0)}, ${b.toFixed(0)})`)
  })
  return result
}
