export const verticalLinePlugin = (color) => {
  return {
    id: 'vertical-line-plugin',
    afterDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.controller.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.tooltipPosition().x;
      const topY = chart.scales['y-axis-0'].top;
      const bottomY = chart.scales['y-axis-0'].bottom;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      
      ctx.globalAlpha = 0.5;
      ctx.strokeStyle = color ? color : 'black';
      ctx.globalAlpha = 1.;
      
      ctx.stroke();
      ctx.restore();
      }
    }
  }
}

export const addPaddingPlugin = () => ({
    id: 'paddingPlugin',
    beforeLayout: (chart) => {
      chart.options.layout.padding = {
        top: 0,
        right: 0,
        bottom: -10,
        left: -10
      };
    }
  });