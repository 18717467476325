import {colors} from '../../../styles/colors'

export const PARAMETER_CONFIG = {
  angle: {
    label: "Angle",
    unit: '°',
    decimals: 2,
    tickConfig: {
      min: 0,
      max: 55,
      display: true,
      beginAtZero: false,
      stepSize: 5,
      callback: function(value) {
        if (value % 2 !== 0) {
          return `${value}`
        }
      }
    },
  },
  duration: {
    label: "Duration",
    unit: 's',
    decimals: 2,
    tickConfig: {
      min: 0,
      max: 3,
      stepSize: 1,
      display: true,
      beginAtZero: true,
      callback: (value) => value
    },
  },
  fraction: {
    label: ['Raise', 'Hold', 'Lower'],
    unit: '%',
    decimals: 2,
    tickConfig: {
      min: 0,
      max: 100,
      stepSize: 20,
      display: true,
      beginAtZero: true,
      callback: (value) => value
    },
    color: [
      colors.lightBlue,
      colors.darkBlue,
      colors.lightGrey,
    ]
  },
}