import React, {Fragment, useState} from 'react';
import {AiOutlineSend} from 'react-icons/ai'
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import patterns from '../../../definitions/patterns';


/**
 * Renders the "MForgotPasswordForm" component.
 * @return {jsx} The about page component.
 */
export const MForgotPasswordForm = ({domain}) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('') 
  
  const handleEmailInput = (event) => {
    if (error) {
      setError('')
    }
    if (success) {
      setSuccess('')
    }

    setEmail(event.target.value)
  }


  const onSendClick = async () => {
    const uidRequest = await fetch(`https://reg.${domain}/${email}/uid`)
    const uidResponse = await uidRequest.json()
    
    if (uidRequest.status !== 200) {
      setError(uidResponse.message)
      return;
    }

    const username = uidResponse.uid
    
    const sendEmailPayload = {
      appId: "nushu-dashboard",
      username: username,
    }
    const sendEmailRequest = await fetch(
      `https://${username}.${domain}/account/request-password-reset`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendEmailPayload)
      }
    )
    if (sendEmailRequest.status === 200) {
      setSuccess('Link sent')
    }
  }

  let helperText
  if (success) {
    helperText = success
  } else if (error) {
    helperText = error
  } else {
    helperText = ''
  }

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography variant="subtitle2">
          Send a reset link to your account&apos;s email address:
        </Typography>
        <Box sx={{ height: 80 }}>
          <TextField
              value={email}
              onChange={handleEmailInput}
              error={Boolean(error)}
              helperText={helperText}
              color={success ? 'success': null}
              focused={Boolean(success)}
              id="email"
              label="Email"
              variant="filled"
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment:
                  <IconButton
                    color='primary'
                    onClick={onSendClick}
                    disabled={!patterns.emailRegex.test(email)}
                    >
                    <AiOutlineSend/>
                  </IconButton>
              }}
            />
        </Box>
      </Stack>
    </Fragment>

  );
};

MForgotPasswordForm.propTypes = {
  domain: PropTypes.string,
};
