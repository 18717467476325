import Pryv from 'pryv';

import { allActivityTypes, gaitActivityTypes } from '../definitions/activityTypes.js';
import { scoreTypes,totalsTypes, trendTypes } from '../definitions/statTypes.js';
import { vibrationTypes } from '../definitions/vibrationTypes.js';

import { arraySum, average, shiftArray, stdDev } from './mathUtils.js';
// Data fetch helper functions

/**
 * Fetch Data
 * @param {String} apiEndpoint
 * @param {Object} apiCall using pryv's call
 * @return {Object} containing value and unit as strings.
 */
export function fetchData(apiEndpoint, apiCall) {
  const connection = new Pryv.Connection(apiEndpoint);
  return connection.api(apiCall);
}

/**
 * Get Content
 * @param {Array} callResponse reulting from an apicall
 * @param {String} streamId streamId by which to filter the response by.
 * @param {any} defaultValue if streamId not found
 * @return {Array} of all events matching of callResponse with streamId
 */
export function getContent(callResponse, streamId, defaultValue = undefined) {
  try {
    const res = callResponse.filter((item) => item.streamId === streamId);
    return res[0]['content'];
  } catch {
    return defaultValue;
  }
}

/**
 * Extract General Patient Data
 * @param {Array} callResponse reulting from an apicall
 * @return {Object} containing general patient data
 */
export function extractGeneralPatData(callResponse) {
  const data = {
    firstName: 'User',
    lastName: 'Not Found',
    birthdate: new Date(),
    age: -1,
    impairedSide: '',
    weight: -1,
    height: -1,
    address: {
      street: '',
      town: '',
      zip: 0,
      country: ''
    },
    phoneNo: '',
    diagnosis: ''
  }

  try {
    const eventArray = callResponse[0]['events'];
    const name = getContent(eventArray, 'name');
    const bdate = new Date(getContent(eventArray, 'birthdate'));

    data.firstName = name['first-name'];
    data.lastName = name['last-name'];
    data.age = new Date(new Date() - bdate).getFullYear() - 1970;
    data.birthdate = bdate;
    data.impairedSide = getContent(eventArray, 'impaired-side');
    data.weight = getContent(eventArray, 'weight');
    data.height = getContent(eventArray, 'height');
    data.address = getContent(eventArray, 'address');
    data.shoeSize = getContent(eventArray, 'shoe-size');
    data.sex = getContent(eventArray, 'sex');
    data.regDate = new Date(
      callResponse[0].events.filter((item) => item.streamId === 'name')[0].created * 1000
    );

    data.address = getContent(eventArray, 'address');
    data.phoneNo = getContent(eventArray, 'phone');


    const diagnosis = eventArray.filter((i) => i.streamId === 'diagnosis');
    data.diagnosis = diagnosis.length === 0 ? 'none' : diagnosis[0];
  } catch {
    console.error(`Error loading user...`)
  }

  return data
}

/**
 * Extract General Doctor Data
 * @param {Array} callResponse reulting from an apicall
 * @return {Object} containing general doctor data
 */
export function extractGeneralDocData(callResponse) {
  const eventArray = callResponse[0]['events'];
  const data = {};

  const name = getContent(eventArray, 'name');

  data.firstName = name ? name['first-name'] : undefined;
  data.lastName = name ? name['last-name'] : undefined;
  data.email = getContent(eventArray, '.email');
  data.affiliation = getContent(eventArray, '.affiliation');
  data.address = getContent(eventArray, 'address');
  data.phoneNo = getContent(eventArray, 'phone');

  return data;
}

export const computePatientTrend = (activities) => {
  const timeline = new Array(14).fill(0);
  for (const activity of activities) {
    const deltaDays = getDateDayDifference(
      new Date(activity.time),
      new Date()
    );
    
    if (deltaDays > 14) {
      continue
    }

    timeline[deltaDays] += 1
  }

  const lastWeek = timeline.slice(0, 7).reduce((a, b) => a + b);
  const lastlastWeek = timeline.slice(7, 14).reduce((a, b) => a + b);

  const trend = lastlastWeek === 0 ? lastWeek : (lastWeek - lastlastWeek) / lastlastWeek;

  if (trend >= 0.1) {
    return '↗';
  } else if (trend <= -0.1) {
    return '↘';
  } else {
    return '=';
  }
}

export const formatOverallStats = (response) => {
  const events = response.events;
  const result = {
    total: {},
    scores: {},
  }

  for (const type of totalsTypes) {
    result.total[type] = getContent(events, type, 0)
  }

  for (const type of scoreTypes) {
    result.scores[type] = getContent(events, type, 0)
  }

  return result
}

/**
 * Format Raw Statistics into JSON and compute some overall numbers
 * @param {Array} callres results from the PatientInfoCall
 * @return {Object}
 */
export function formatTrendStats(callRes) {
  // Make default trend stats filled with zeroes
  const trendStats = {}
  for (const at of [...gaitActivityTypes.map(x => x.id), 'all']) {
    trendStats[at] = {}
    for (const [idx, trend] of trendTypes.entries()) {
      if (idx < 4) {
        trendStats[at][trend] = {
          values: new Array(365).fill(0)
        };
      } else {
        trendStats[at][trend] = {
          'values-left': new Array(365).fill(0),
          'values-right': new Array(365).fill(0)
        };
      }
    }
  }

  // Exit if no stats are available
  if (callRes[0].events.length === 0) {
    return trendStats
  }

  const deltaDays = getDateDayDifference(
    new Date(callRes[0].events[0].time * 1000),
    new Date()
  );


  // Global running idx
  let idx = 0;
  gaitActivityTypes.forEach((at) => {
    try {
      trendTypes.forEach((st, jj) => {
        // Shift stats by one day if necessary
        if (jj < 4) {
          trendStats[at.id][st].values = shiftArray(
            callRes[idx].events[0].content.values,
            deltaDays
          );
        } else {
          for (const side of ['left', 'right']) {
            trendStats[at.id][st][`values-${side}`] = shiftArray(
              callRes[idx].events[0].content[`values-${side}`],
              deltaDays
            );
          }
        }
  
        // Flip left supination angle
        if (st.includes('supination')) {
          const toFlip = trendStats[at.id][st]['values-left'];
          trendStats[at.id][st]['values-left'] = toFlip.map((x) => x * -1);
        }
        // Convert heel clearance to cm
        if (st.includes('heel-clearance')) {
          for (const key of Object.keys(trendStats[at.id][st])) {
            const toc = trendStats[at.id][st][key];
            trendStats[at.id][st][key] = toc.map((x) => x * 100);
          }
        }
      idx ++
      });
    } catch (e) {
      console.warn(`Failed formatting stats for ${at.id}... Looks like none are available.`)
      idx += trendTypes.length
    }
  });

  // Compute totals across all activity types
  for (const [idx, trend] of trendTypes.entries()) {
    for (const at of gaitActivityTypes) {
      if (idx < 4) {
        trendStats.all[trend].values = arraySum(
          trendStats.all[trend].values,
          trendStats[at.id][trend].values,
        )
      } else {
        const stepRatio = trendStats[at.id].steps.values.map( (val, jj) => {
          if (trendStats.all.steps.values[jj] === 0) {
            return 0 
          } else {
            return val / trendStats.all.steps.values[jj]  
          }
        })
        for (const side in trendStats.all[trend]) {
          trendStats.all[trend][side] = arraySum(
            trendStats.all[trend][side],
            trendStats[at.id][trend][side].map( (val, jj) => stepRatio[jj] * val)
          )
        }
      }
    }
  }

  return trendStats;
}


export const getTotalsFromLastWeek = (overallTrend) => {
  const result = {}
  for (const stat of totalsTypes) {
    result[stat] = overallTrend[stat.split('-')[1]].values.slice(-7).reduce( (a, b) => a + b)
  }

  return result
}


export const extractActivity = (metaEvent) => {
  let vibration = null;
  for (const vt of vibrationTypes) {
    if (metaEvent.streamIds.includes(vt.id)) {
      vibration = vt.id;
      break;
    }
  }

  let analysisStatus = 'unanalyzed'
  if (metaEvent.streamIds.includes('analyzed')) {
    analysisStatus = 'analyzed'
  } else if (metaEvent.streamIds.includes('failed')) {
    analysisStatus = 'failed' 
  }

  // Set all activity types that are still TBI to unanalyzed.
  // Can be removed once the dedicated pages have been implemented.
  if (!allActivityTypes.map(type => type.id).includes(metaEvent.content.title)){
    analysisStatus = 'unanalyzed'
  }

  return {
    analysisStatus: analysisStatus,
    id: metaEvent.content['activity-id'],
    type: metaEvent.content.title,
    time: metaEvent.time * 1000,
    vibration: vibration,
  }
}

const formatGaitResults = (data) => {
  addTotalStats(data);
  flipSupAngles(data);
  convertClearanceToCm(data);
  addVariabilityIdx(data);
  addSymmetryIdx(data);
}

const formatPosturographyResults = (data) => {
  if (!data.variant) {
    data.variant = 'full'
  }
  return data
}


const formatter = {
  'result-magnes/nushu-v3': formatGaitResults,
  'result-magnes/nushu-v2': formatGaitResults,
  'result-magnes/posturography-v1': formatPosturographyResults,
  'result-magnes/posturography-v2': formatPosturographyResults,
  'result-magnes/simple-haptic-reaction-time-v1': (data) => data ,
  'result-magnes/heel-raises-v1': (data) => data,
  'result-magnes/toe-tapping-v1': (data) => data,
}

/**
 * Format Assessment Data
 * By extracting data from the apicall and computing stats such as the
 * variability indices and
 * @param {Array} rawResponse of the apicall to fetch an assessment.
 * @return {Array} of formatted assessment data objects
 */
export function formatAssessmentData(rawResponse) {
  const formattedResults = []
  rawResponse[0]['events'].forEach((event) => {
    const data = {};
    data.date = new Date(event.time * 1000);
  
    for (const [key, value] of Object.entries(event['content'])) {
      data[key] = value;
    }
    
    try {
      formatter[event.type](data);
    } catch {
      console.warn(`${event.type} has no dedicated formatter.`)
    }

    formattedResults.push(data);
  })

  return formattedResults;
}

/**
 * Add Total Stats
 * which consist of cadence, distance duration and steps to a data object. This
 * is done by looping over all rows and summing up the respective stats.
 * Also computes the walk ratio ( stride length / cadence )
 * @param {Object} data of the apicall to fetch an assessment.
 */
export function addTotalStats(data) {
  const totalStats = {};
  const parametersList = ['steps', 'cadence', 'distance', 'duration', 'velocity'];

  for (const p of parametersList) {
    totalStats[p] = 0;
  }
  const allStrideLengths = [];

  for (const row of data.rows) {
    parametersList.forEach((p) => {
      if (p === 'cadence' || p === 'velocity') {
        totalStats[p] += row['row-stats'][p] * row['row-stats']['steps'];
      } else {
        totalStats[p] += row['row-stats'][p];
      }
    });
    const strideLengths = row.L['length'].values.concat(row.R.length.values);
    allStrideLengths.push(...strideLengths);
  }

  totalStats['cadence'] /= totalStats['steps'];
  totalStats['velocity'] /= totalStats['steps'];

  const avgStrideLength = average(allStrideLengths);
  totalStats['walk-ratio'] = (avgStrideLength / totalStats['cadence']) * 60;

  data.totalStats = totalStats;
}

/**
 * Add variabily IDX
 * to the data object.aggregateStats
 * @param {Obj} data from and to which variability indices are added.
 */
export function addVariabilityIdx(data) {
  for (const row of data.rows) {
    ['L', 'R'].forEach((side) => {
      for (const [para, value] of Object.entries(row[side])) {
        row[side][para]['varIDX'] = value.avg === 0 ? value.std : value.std / Math.abs(value.avg);
      }
    });
  }
}

/**
 * Add symmetry IDX
 * computed by SL = 100 * |L| / |L + R| & SR = 100 - SL, where L & R in this
 * this case refer to the left and right mean of a parameter (e.g. stride
 * length) to the data object.
 * @param {Obj} data from and to which symmetry indices are added.
 */
export function addSymmetryIdx(data) {
  for (const row of data.rows) {
    Object.keys(row['L']).forEach((para) => {
      const avgL = row['L'][para].avg;
      const avgR = row['R'][para].avg;
      if (Math.abs(avgL + avgR) === 0) {
        row['L'][para]['symIDX'] = 50;
      } else {
        row['L'][para]['symIDX'] = (100 * Math.abs(avgL)) / Math.abs(avgL + avgR);
      }
      row['R'][para]['symIDX'] = 100 - row['L'][para]['symIDX'];
    });
  }
}

/**
 * Flip supination Angles
 * of the left side, aka multiply by (-1).
 * @param {Obj} data of an assessment in which angles are to be flipped.
 */
export function flipSupAngles(data) {
  // Abbreviations
  const oss = 'overall-step-stats';
  const sa = 'supination_angle';
  // Handle overall step stats
  for (const [para, val] of Object.entries(data[oss]['L'][sa])) {
    let newVal = val;
    if (!para.includes('std')) {
      newVal = val * -1;
    }
    data[oss]['L'][sa][para] = newVal;
  }
  // Handle each row
  for (const row of data.rows) {
    for (const [para, val] of Object.entries(row['L'][sa])) {
      let newVal = val;

      if (para === 'values') {
        newVal = val.map((x) => x * -1);
      } else if (!para.includes('std')) {
        newVal = val * -1;
      }
      row['L'][sa][para] = newVal;
    }
  }
}

/**
 * Convert heel clearance from m to cm
 * @param {Obj} data of an assessment in which heel clearance to be converted.
 */
export function convertClearanceToCm(data) {
  // Abbreviations
  const oss = 'overall-step-stats';
  const sa = 'heel-clearance';

  for (const side of ['L', 'R']) {
    // Handle overall step stats
    for (const [para, val] of Object.entries(data[oss][side][sa])) {
      data[oss][side][sa][para] = 100 * val;
    }
    // Handle each row
    for (const row of data.rows) {
      for (const [para, val] of Object.entries(row[side][sa])) {
        if (para === 'values') {
          row[side][sa][para] = val.map((x) => x * 100);
        } else {
          row[side][sa][para] *= 100;
        }
      }
    }
  }
}

/**
 * Get Date Day Difference
 * Difference between two dates in days, i.e. 1/1 @ 23:10 vs. 2/1 @ 00:10
 * corresponds to one day.
 * @param {Date} date1 the first date.
 * @param {Date} date2 the second date (occuring later than date 1).
 * @return {number} difference between the two dates in 'days'
 */
export function getDateDayDifference(date1, date2) {
  const d1 = new Date(date1.getTime());
  d1.setHours(12, 0, 0, 0);
  const d2 = new Date(date2.getTime());
  d2.setHours(12, 0, 0, 0);

  return Math.round((d2.getTime() - d1.getTime()) / (1000 * 24 * 3600));
}

/**
 * aggregateStats runs over a list of assessments and compiles
 * their overall stats
 * @param {array} assessments formatted by formatAssessmentData()
 * @return {object} of similar structure like a single formatted assessment.
 */
export function aggregateStats(assessments) {
  // Construct aggreggate stats object
  const ss = 'step-stats';
  const os = {
    totalStats: {
      steps: 0,
      distance: 0,
      duration: 0,
      cadence: 0,
      velocity: 0
    },
    'step-stats': {}
  };

  for (const side of ['L', 'R']) {
    os[ss][side] = {};
    for (const param of Object.keys(assessments[0]['rows'][0][side])) {
      os[ss][side][param] = { values: [] };
    }
  }

  // Compute total steps, i.e. number of steps, distance , etc...
  assessments.forEach((assessment) => {
    for (const key of Object.keys(os.totalStats)) {
      // Compute total steps, i.e. number of steps, distance , etc...
      os.totalStats[key] += assessment.totalStats[key];
      // Get parameters for each step of selection
      for (const row of assessment.rows) {
        for (const side of ['L', 'R']) {
          for (const para of Object.keys(os[ss][side])) {
            if (['distance', 'stability'].includes(para)) {
              continue;
            }
            os[ss][side][para].values.push(...row[side][para]['values']);
          }
        }
      }
    }
  });

  // 'Clean up'
  const N = os.totalStats.steps;

  os.totalStats.velocity = os.totalStats.distance / os.totalStats.duration;
  os.totalStats.cadence = (N / os.totalStats.duration) * 60;

  for (const side of ['L', 'R']) {
    for (const para of Object.keys(os[ss][side])) {
      if (['distance', 'stability'].includes(para)) {
        continue;
      }
      const vals = os[ss][side][para]['values'];
      const avg = average(vals);
      os[ss][side][para]['avg'] = avg;
      os[ss][side][para]['std'] = stdDev(vals, avg, vals.length);
      os[ss][side][para]['min'] = Math.min(...vals);
      os[ss][side][para]['max'] = Math.max(...vals);
    }
  }

  // Modified os object to be able to get varIDX and symIDX
  addVariabilityIdx({ rows: [os[ss]] });
  addSymmetryIdx({ rows: [os[ss]] });
  return os;
}


export const formatDoctorInfo = (events) => {
  const name = getContent(events, 'name')
  return {
    username: getContent(events, '.username'),
    firstName: name['first-name'],
    lastName: name['last-name'],
    address: getContent(events, 'address'),
    phone: getContent(events, 'phone'),
    affiliationShorthand: getContent(events, '.affiliation'),
    email: getContent(events, '.email'),
  };
}


export const extractMeanRombergValues = (results, torqueProxy = 'omega') => {
  const parameters = ['romberg-ratio', 'alternative-romberg-ratio'];
  
  const res = {}
  for (const para of parameters) {
    res[para] = {'lateral': 0, 'anteposterior': 0}
    for (const dir in res[para]){
      for (const side of ['L', 'R']) {
        res[para][dir] += results[para][side][torqueProxy][dir];
      } 
      res[para][dir] /= 2
    }
  }

  return res;
}

export const createOverallDataset = (rowResults) => {
  const results = {};
  for (const side in rowResults[0]) {
    results[side] = {};
    for (const para in rowResults[0][side]) {
      results[side][para] = [];
    }
  }
  for (const row of rowResults) {
    for (const side in row) {
      for (const para in row[side]) {
        results[side][para] = results[side][para].concat(row[side][para].values)
      }
    }
  }
  return results
}

export const getSortingIndexes = (array) => {
  const indexedArray = new Array(array.length).fill().map((_, idx) => [idx, array[idx]])
  const sortedIdxArray = indexedArray.sort( (a, b) => a[1] - b[1] )

  const newIndexes = new Array(array.length)
  sortedIdxArray.forEach((x, idx) => {
    newIndexes[x[0]] = idx
  })

  return newIndexes
}

export const sortByIndexes = (array, newIndexes) => {
  const res = Array.from(array.length).fill(0)
  array.forEach((value, idx) => {
    res[newIndexes[idx]] = value;
  })

  return res
}
