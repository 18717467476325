
const emailRegex = /^([a-zA-Z0-9_\-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const usernameRegex = /^d-[a-z0-9]{8}$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

const streetnameRegex=/^[a-zA-Z0-9.\-_./_ _]+$/g
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g

const numbersOnlyRegex=/^[0-9]*$/
const lettersOnly = /[A-Za-z]$/g

export default {
  emailRegex,
  lettersOnly,
  numbersOnlyRegex,
  passwordRegex,
  phoneRegex,
  streetnameRegex,
  usernameRegex,
}

