import React from 'react';
import { Bar, Doughnut,Line } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { verticalLinePlugin } from '../../../components/plugins/ChartPlugins';

const mttconf = require('./MTTConfig')

export const MTTDoughnut = ({countLeft, countRight}) => {
  const theme = useTheme()

	const data = {
    labels: ["left", "right"],
    datasets: [{
      data: [countLeft, countRight],
      backgroundColor: [
        theme.palette.info.main,
        theme.palette.info.light,
      ],
      borderWidth: 1,
    }]
  }

  const options = {     
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    rotation: -Math.PI
  };

  return (
		<Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
				<Doughnut data={data} options={options}/>
				<Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
					<Box>
						<Typography>
							{countLeft + countRight}
						</Typography>
					</Box>
					<Box>
						<Typography sx={{fontSize: 13}}>
							{[countLeft, countRight].join(" | ")}
						</Typography>
					</Box>
				</Box>
		</Box>
	)
}


MTTDoughnut.propTypes = {
  countLeft: PropTypes.int,
	countRight: PropTypes.int,
};


export const MTTOverallResult = ({param, data, side, total}) => {
  const theme = useTheme()
	const color = (side === 'Left') ? theme.palette.info.light : theme.palette.info.main
  
  const { label, unit, conversion, decimals, range } = mttconf.PARAMETER_DEFS[param];
  
	let datapoints = data
  let totalStats = total
	if (conversion) {
    datapoints = data.map(set => ({mean: conversion(set.mean), std: conversion(set.std)}))
    for (const [key, value] of Object.entries(totalStats)) {
      totalStats[key] = conversion(value)
    }
	}

	let datasets = []
	datasets.push(
		{
			label: label,
			data: datapoints.map((set, idx) => ({x: idx + 1, y: set.mean})),
			fill: false,
			borderWidth: 2,
			backgroundColor: `${color}60`,
			borderColor: color
		}
	)

  if (param !== "reps") {
    datasets.push(
      {
        label: "min",
        data: datapoints.map((set, idx) => ({x: idx + 1, y: set.mean - set.std})),
        fill: false,
        pointHitRadius: 0,
        pointRadius: 0.1,
        borderWidth: 1,
        borderColor: `${color}20`,
        backgroundColor: `${color}20`,
      },
      {
        label: "max",
        data: datapoints.map((set, idx) => ({x: idx + 1, y: set.mean + set.std})),
        fill: "-1",
        pointHitRadius: 0,
        borderWidth: 1,
        pointRadius: 0.1,
        borderColor: `${color}30`,
        backgroundColor: `${color}30`,
      },
      {
        label: "minmin",
        data: datapoints.map((set, idx) => ({x: idx + 1, y: set.mean - 2 * set.std})),
        fill: false,
        pointHitRadius: 0,
        pointRadius: 0.1,
        borderWidth: 1,
        borderColor: `${color}30`,
        backgroundColor: `${color}30`,
      },
      {
        label: "maxmax",
        data: datapoints.map((set, idx) => ({x: idx + 1, y: set.mean + 2 * set.std})),
        fill: "-1",
        pointHitRadius: 0,
        borderWidth: 1,
        pointRadius: 0.1,
        borderColor: `${color}30`,
        backgroundColor: `${color}30`,
      },
    )
  }

  const chartData = {
    labels: Array.from({length: datapoints.length}, (_, idx) => idx + 1),
    datasets: datasets,
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      intersect: false,
      mode: 'index',
      yAlign: "top",
      callbacks: {
        title: (context) => {
          if (context.datasetIndex !== 0) return
          return `${label} - Set #${context.xLabel}:`
        },
        label: (context) => {
          if (context.datasetIndex !== 0) return
          return [
            `mean: ${context.yLabel.toFixed(decimals)}${unit}`,
            `std: ${datapoints[context.index].std.toFixed(decimals)}${unit}`
          ]
        },
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: `${side} Set No.`
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: `${unit}`,
        },
				ticks: {
					min: range[0],
					max: range[1],
					display: true,
					stepSize: range[1],
				},
        gridLines: {
          display: true,
        },
      }]
    },
  }

  return (
    <Box sx={{display: 'flex', height: '100%', width: '100%', flexDirection: 'column', p: 1}}>
      <Grid container justifyContent="center" sx={{height: 20, width: '100%'}}>
				<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
          {(param !== "reps") && 
            <Typography align="center" variant="caption" sx={{ fontSize: 12 }}>
              {`${totalStats.mean.toFixed(decimals)} ± ${totalStats.std.toFixed(decimals)}${unit}`}
            </Typography>
          }
				</Grid>
      </Grid>
      <Box sx={{display: 'flex', height: `calc(100% - 30px)`, width: '100%' }}>
        {param !== 'reps' ? (
          <Line data={chartData} options={chartOptions}/>
          ) : (
          <Bar data={chartData} options={chartOptions}/>
        )}
      </Box>
    </Box>
  )
}

MTTOverallResult.propTypes = {
  param: PropTypes.string,
	data: PropTypes.array,
	side: PropTypes.string,
  total: PropTypes.object,
};



export const MTTSetResultGraph = ({datasets, param, side, options}) => {
  
  const colors = mttconf.generateColorMap(mttconf.RGB_COLORS[side], datasets.length)
  const { unit, conversion, decimals, stepSize } = mttconf.PARAMETER_DEFS[param];

  let maxY = options.maxY
  if (conversion) {
    maxY = conversion(options.maxY);
  }


  let chartLabels = Array.from({length: options.maxX}, (_, idx) => idx + 1)
  if (param === 'frequency') {
    chartLabels = Array.from({length: options.maxX}, (_, idx) => (
      idx / (options.maxX - 1) * mttconf.SET_DURATION).toFixed(2)
    )
  }

  let chartDatasets = []
  for (let i=0; i < datasets.length; i++) {
    const datapoints = datasets[i].map( (value, idx) => {
      let y = value
      if (conversion){
        y = conversion(value)
      }

      y = Math.round(y * 10 ** decimals) / 10 ** decimals

      let x = idx + 1
      if (param === 'frequency') {
        x = mttconf.FS * idx  
      }
      return {
        x: x,
        y: y
      }
    })
    chartDatasets.push(
      {
        label: `Set #${i + 1}`,
        data: datapoints,
        fill: false,
        borderWidth: 3,
        backgroundColor: `${colors[i]}`,
        borderColor: `${colors[i]}`,
        pointRadius: 0,
      }
    )
  }
  const chartData = {
    labels: chartLabels,
    datasets: chartDatasets,
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: 'index',
      yAlign: "top",
      callbacks: {
        title: (ctx) => (param !== 'frequency') ? `Tap No. ${ctx[0].label}` : `Time ${ctx[0].label} [s]`
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: (param !== 'frequency') ? `${side} Tap No.` : `Set Time [s]`
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: `${unit}`,
        },
				ticks: {
          min: 0,
					max: stepSize ? (Math.floor(maxY / stepSize) + 1) * stepSize : (Math.ceil(maxY / 2)) * 2,
					display: true,
				},
        gridLines: {
          display: true,
        },
      }]
    },
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Line data={chartData} options={chartOptions} plugins={[verticalLinePlugin()]}/>
    </Box>
  )
}

MTTSetResultGraph.propTypes = {
	datasets: PropTypes.array,
  side: PropTypes.string,
  param: PropTypes.string,
  options: PropTypes.object,
};

