import React, { Fragment, useState } from 'react';
import { Box, Button, CircularProgress,Dialog, Divider, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MCustomSnackbar } from '../../../components/ui/MCustomSnackbar.js';
import { MSelectAssessmentsList } from '../../../components/ui/MSelectAssessmentsList.js';
import { useGlobalContext } from '../../../context/AppContext.js';
import { assessmentCall, getRegisteredAffiliations, pryvApiCall } from '../../../data/apiCalls.js';
import enviromnents from '../../../definitions/environments.json';
import { loadPatientData } from '../../../utils/auxFunctions.js';
import { formatDoctorInfo } from '../../../utils/dataFetchFunctions.js';
import { annotateRemoteReport, downloadPdf } from '../../../utils/pdfTools.js';


const NON_REPORTABLE_ACTS = [
  'posturography',
  'heel-raises',
  'toe-tapping',
  'simple-haptic-reaction-time',
]
/**
 * /home/debian/nushu_api/cache/axfwruwtui/report.pdf
 * Handles Trend Report Generation.
 * @param {Object} props The component properties.
 * @return {jsx} The trend chart component.
 */
export const MTrendReportGenerator = ({isOpen, setOpen}) => {
  const [selected, setSelected] = useState([]);
  const [isGenerating, setGenerating] = useState(false)
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false
  });

  const instance = window.sessionStorage.getItem('dbInstance')
  const nushuApiURL = enviromnents[instance].endpoints.nushu_api_url
  
  const { doctorInfoEvents } = useGlobalContext();
  
  const patient = loadPatientData();
  const activities = patient.activities.filter((act) => (
    act.analysisStatus == 'analyzed' && !NON_REPORTABLE_ACTS.includes(act.type)
  ))

  const onClose = () => {
    setSelected([])
    setGenerating(false)
    setOpen(false)
  }
  const onGenerateClick = async () => {
    setGenerating(true)

    const fetchResults = []

    for (const item of selected) {
      fetchResults.push(...assessmentCall(item.id))
    }
    
    const call = [{
      method: 'callBatch',
      params: fetchResults,
    }]



    const parsedResults = await pryvApiCall(patient.apiToken, call)
      .then((res) => {
        return res[0].results.map( (x, idx) => {
          const unix = new Date(x.events[0].time * 1000) 
          return ({
            meta: {
              time: unix.getTime() / 1000 - unix.getTimezoneOffset() * 60,
              id: selected[idx].id,
              type: selected[idx].type,
              vibration: selected[idx].vibration ? selected[idx].vibration : 'none'
            },
            statistics: {
                L: {
                  steps: x.events[0].content.probabilities.L.length,
                  ...x.events[0].content['overall-step-stats'].L
                },
                R: {
                  steps: x.events[0].content.probabilities.R.length,
                  ...x.events[0].content['overall-step-stats'].R
                },
              }
          })
        })
      })
    const registry = await getRegisteredAffiliations(instance)
    const doctor = formatDoctorInfo(doctorInfoEvents)
    
    const reportRequestBody = {
      authUrl: sessionStorage.getItem('docEP'),
      patient: {
        username: patient.username,
        name: `${patient.firstName} ${patient.lastName}`,
        birthday: patient.birthdate.getTime() / 1000,
        height: patient.height,
        weight: patient.weight
      },
      doctor: {
        username: doctor.username,
        name: `${doctor.firstName} ${doctor.lastName}`,
        email: doctor.email,
        phone: doctor.phone ? doctor.phone : "",
        affiliation: registry ? registry[doctor.affiliationShorthand].name : 'n/a'
      },
      activities: parsedResults
    }

    const report = await fetch(`${nushuApiURL}/generate-trend-report`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportRequestBody)
    })
    .then(resp => resp.arrayBuffer())
    .catch(resp => {
      console.error(resp);
      return
    })
    const filename = `${patient.firstName[0]}${patient.lastName}_trend`
    
    annotateRemoteReport(
      report,
      doctor.affiliationShorthand,
    )
    .then((annotatedReport) => downloadPdf(annotatedReport, filename))
    .then(() => {
      setOpen(false)
      setSnackbar({
        message: 'Generated Trend Report',
        severity: 'success',
        show: true
      })
    })
    .catch(() => setSnackbar({
      message: 'Failed to generate Trend Report',
      severity: 'error',
      show: true
    }))


    setGenerating(false)
  }

  return(
    <Fragment>
      {snackbar.show && (
        <MCustomSnackbar
          content={snackbar}
          handleClose={() => setSnackbar({
            message: '',
            severity: '',
            show: false
          })}
        />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        <Typography
          align="center"
          variant="h6"
          sx={{
            my: 2
          }}
        >
          Assessments to include
        </Typography>
        <MSelectAssessmentsList
            activities={activities}
            group={'E'}
            setSelection={setSelected}
            isComputing={false}
            setOpen={setOpen}
          />
        
        <Divider />
        <Box sx={{display: 'flex', p: 1, width: '100%', justifyContent: 'center' }}>
          {isGenerating ? (
            <CircularProgress color="secondary" size={25} />
          ) : (
            <Button variant="contained" disabled={!(selected.length > 0) || (isGenerating)} onClick={onGenerateClick}>
              Generate
            </Button>
          )}
        </Box>
      </Dialog>
    </Fragment>
  )
}

MTrendReportGenerator.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
  };

