import { Divider, Typography } from "@mui/material"
import { PropTypes } from 'prop-types';

import { average, stdDev } from "../../../utils/mathUtils";

import { MTTDoughnut, MTTOverallResult } from "./MTTMiniplots";
import { MTTSetResults } from "./MTTSetResult";

const mttconf = require("./MTTConfig")

export const MTTResults = ({results}) => {
  let combinedSetResults = {}
  let overallResults = {}
  
  for (let side of ["L", "R"]) {
    combinedSetResults[side] = {}
    overallResults[side] = {}
    
    for (let set of results[side]) {
      for (let param of mttconf.TOE_TAPPING_PARAMETERS) {
        if (!combinedSetResults[side][param]) {
          combinedSetResults[side][param] = []
        }
        if (!overallResults[side][param]) {
          overallResults[side][param] = []
        }

        const values = set.taps.map(tap => tap[param])
        combinedSetResults[side][param].push({
          mean: average(values),
          std: stdDev(values),
        })
        
        overallResults[side][param].push(values)
      }
      
      if (!combinedSetResults[side].frequency) {
        combinedSetResults[side].frequency = []
      }
      combinedSetResults[side].frequency.push({
        mean: average(set.time_frequency.frequency),
        std: stdDev(set.time_frequency.frequency),
      })
      
      if (!overallResults[side].frequency) {
        overallResults[side].frequency = []
      }
      overallResults[side].frequency.push(set.time_frequency.frequency)
    }

    combinedSetResults[side].reps = results[side].map( (set)  => {
      return {
        mean: set.reps,
        std: 0,
      }
    })
  }

  const extractOverallStats = (side, param) => {
    let res = {}
    try {
      const values = overallResults[side][param].flat();
      res = {
        mean: average(values),
        std: stdDev(values), 
      }
    } catch {
      res = {
        mean: 0,
        std: 0,
      }
    }
    return res
  }
  const parameters = Object.keys(mttconf.PARAMETER_DEFS);
  
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: 'calc((100vh - 160px))', width: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row', height: '40%', width: '100%', padding: 5}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', width: '15%', padding: 10}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '50%', width: '100%' }}>
            <Typography align='center' variant='overline'>
              Total Sets
            </Typography>
            <div style={{display: 'flex', height: 'calc(100% - 35px)'}}>
              <MTTDoughnut countLeft={results.L.length} countRight={results.R.length}/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', height: '50%', width: '100%' }}>
            <Typography align="center" variant='overline'>
              Total Tap Count
            </Typography>
            <div style={{display: 'flex', height: 'calc(100% - 35px)'}}>
              <MTTDoughnut countLeft={results.L.reduce((count, set) => count + set.reps, 0)} countRight={results.R.reduce((count, set) => count + set.reps, 0)}/>
            </div>
          </div>
        </div>
        { parameters.map( (param) => { 
          return (
            <div key={param} style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', width: `calc((100% - 15%) / ${parameters.length})` }}>
              <Typography align="center" variant='overline'>
                {param}
              </Typography>
              <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)', padding: 2}}>
                <MTTOverallResult param={param} data={combinedSetResults.L[param]} side={"Left"} total={extractOverallStats("L", param)}/>
              </div>
              <Divider sx={{width: "100%" }} />
              <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
                <MTTOverallResult param={param} data={combinedSetResults.R[param]} side={"Right"} total={extractOverallStats("R", param)}/>
              </div>
            </div>
          )
        })}
      </div>
      <Divider orientation='horizontal' />
      <div style={{display: 'flex', flexDirection: 'column', height: '60%', width: '100%', justifyContent: 'center'}}>
        <MTTSetResults overallResults={overallResults}/>
      </div>
    </div>  
  )
}


MTTResults.propTypes = {
  results: PropTypes.object,
}