import React from 'react';
import { Avatar, Box, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Renders Statiscs card.
 * @param {Object} props The component properties.
 * @return {jsx} Statistics card compnent.
 */
export const MStatisticsCard = ({ title, icon, children, color, paperColor }) => {
  const Icon = icon;
  return (
    <Stack
      justifyContent="center"
      alignItems="stretch"
      sx={{ display: 'flex', position: 'relative', height: '100%', width: '100%' }}
    >
      <Avatar
        variant="rounded"
        align="center"
        sx={{
          top: 16,
          width: 30,
          height: 30,
          zIndex: 10,
          alignSelf: 'center',
          backgroundColor: color
        }}
      >
        <Icon sx={{ fontSize: 18 }} />
      </Avatar>
      <LinearProgress value={0} variant="determinate" sx={{ backgroundColor: color }} />
      <Paper sx={{ display: 'flex', height: '100%', justifyContent: 'center', backgroundColor: paperColor ? paperColor : 'white' }}>
        <Stack alignItems="stretch" sx={{ width: '100%', marginTop: 1.5 }}>
          <Typography align="center" variant="overline" sx={{ fontSize: 9 }}>
            {title}
          </Typography>
          <Box sx={{ height: 1 }}>
            {children}
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
};

MStatisticsCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.node,
  color: PropTypes.string,
  paperColor: PropTypes.string
};
