import React, { useEffect, useRef} from 'react'
import { PropTypes } from 'prop-types';


export const DPIAwareCanvas = ({ draw, aspectRatio, ...rest }) => {
  
  const canvasRef = useRef(null)
  const containerRef = useRef(null)
  const subContainerRef = useRef(null)

  useEffect(() => {
    if (!canvasRef.current) return;
   
    const canvas = canvasRef.current
    const container = containerRef.current
    const subContainer = subContainerRef.current

    const context = canvas.getContext('2d')
    let frameCount = 0

    const resizeCanvas = () => {
      const { width, height } = container.getBoundingClientRect()

      let canvasWidth;
      let canvasHeight;
      
      if (aspectRatio) {
        const containerAspectRatio = width / height;
        
        if (containerAspectRatio > aspectRatio) {
          canvasHeight = container.clientHeight;
          canvasWidth = canvasHeight * aspectRatio;
        } else {
          canvasWidth = container.clientWidth;
          canvasHeight = canvasWidth / aspectRatio;
        }

        subContainer.style.width = `${canvasWidth}px`;
        subContainer.style.height = `${canvasHeight}px`;
      }
      
      const dpi = window.devicePixelRatio
      
      canvas.width = canvasWidth * dpi
      canvas.height = canvasHeight * dpi
      canvas.style.width = `${canvasWidth}px`
      canvas.style.height = `${canvasHeight}px`
      
      context.scale(dpi, dpi)

      draw(context, frameCount)
    }

    window.addEventListener('resize', resizeCanvas)
    resizeCanvas(context, frameCount)

    return () => {
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [draw])

  return (
    <div ref={containerRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <div ref={subContainerRef} style={{ display: 'flex'}}>
        <canvas ref={canvasRef} {...rest} />
      </div>
    </div>
  )
}

DPIAwareCanvas.propTypes = {
  draw: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number
}
