import React, { Fragment, useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaClock,
  FaHourglassHalf,
  FaPercentage,
  FaRoute,
  FaShoePrints,
  FaStopwatch,
  FaTachometerAlt,
  FaWalking
} from 'react-icons/fa';
import { MdVibration } from 'react-icons/md';
import { MdAccountCircle } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MCommentField } from '../../components/ui/MCommentField.js';
import { MStatisticsCard } from '../../components/ui/MStatisticsCard.js';
import { MTooltipIconButton } from '../../components/ui/MTooltipIconButton';
import { MUnit } from '../../components/ui/MUnit.js';
import { useGlobalContext } from '../../context/AppContext.js';
import { eventsFromStreams, pryvApiCall } from '../../data/apiCalls.js';
import { id2TitleMap } from '../../definitions/activityTypes.js';
import { getReferenceRange } from '../../definitions/gaitParameterRefRanges.js';
import { colors } from '../../styles/colors.js';
import {
  loadPatientData,
  makeDateString,
  makeDistanceString,
  makeTimeString
} from '../../utils/auxFunctions.js';
import { formatDoctorInfo } from '../../utils/dataFetchFunctions.js';
import { annotatePdf, downloadPdf, fetchPdf } from '../../utils/pdfTools.js';

const ITEMS_PER_ROW = 4;
const DATE_FORMAT = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

/**
 * Assessment details component
 * @return {jsx} The Assessment details component.
 */
export const MAssessmentDetails = ({ analysisResults }) => {
  const [isMarked, setMarked] = useState(false);

  const { doctorInfoEvents } = useGlobalContext();

  const theme = useTheme();
  const history = useHistory();

  const patientData = loadPatientData();
  const patientName = patientData.firstName + ' ' + patientData.lastName;
  const apiToken = patientData.apiToken;

  const activityID = analysisResults.id;
  const markedActivities = new Set(JSON.parse(sessionStorage.getItem('markedActivities')));

  useEffect(() => {
    setMarked(markedActivities.has(activityID));
  }, [activityID]);

  const markForComparisonClick = () => {
    if (isMarked) {
      markedActivities.delete(activityID);
    } else {
      markedActivities.add(activityID);
    }

    sessionStorage.setItem('markedActivities', JSON.stringify([...markedActivities]));
    setMarked(!isMarked);
  };

  const downloadPdfClick = async () => {
    try {
      const assessmentDate = analysisResults.date.toISOString().slice(0, -8);

      const filename =
        `${patientData.firstName[0]}${patientData.lastName}_${analysisResults.type}_${assessmentDate}`
          .replaceAll('-', '')
          .replaceAll(':', '');
      
      const commentText = await pryvApiCall(
        patientData.apiToken,
        eventsFromStreams({
          all: ['activity-comments'],
          any: [`activity-${activityID}`]
        })
      )
        .then((res) => res[0].events[0].content)
        .catch( (e) => {
          console.error(e)
          return ''
        })


      fetchPdf(apiToken, activityID, filename)
        .then((pdf) => {
          return annotatePdf(
            pdf,
            patientData,
            formatDoctorInfo(doctorInfoEvents),
            commentText
          );
        })
        .then((aPdf) => {
          downloadPdf(aPdf, filename);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const backToPatientClick = () => {
    history.push({ pathname: '/patient/' + patientData.username });
  };

  const statByRow = () => {
    const noOfRows = Math.ceil(7 / ITEMS_PER_ROW);
    return [...Array(noOfRows).keys()];
  };

  const makeTime = (date) => {
    let min = date.getMinutes().toString();
    if (min.length < 2) {
      min = '0' + min;
    }
    return date.getHours().toString() + ':' + min;
  };

  const generalDetails = () => [
    {
      title: 'Patient',
      icon: MdAccountCircle,
      value: patientName
    },
    {
      title: 'Date',
      icon: FaCalendarAlt,
      value: makeDateString(analysisResults.date, DATE_FORMAT)
    },
    {
      title: 'Time',
      icon: FaClock,
      value: makeTime(analysisResults.date)
    },
    {
      title: 'Type',
      icon: FaWalking,
      value: id2TitleMap(analysisResults.type)
    }
  ];

  const formatSummaryStats = (stats, pHeight) => {
    const activitySpecfifcStats =
      stats.type !== 'tug'
        ? [
            {
              title: 'Normed Speed',
              icon: FaTachometerAlt,
              extract: function () {
                this.value = (stats.velocity / (pHeight / 100)).toFixed(2);
                this.unit = '/s';
              }
            },
            {
              title: 'Walk Ratio',
              icon: FaPercentage,
              extract: function () {
                this.value = stats['walk-ratio'].toFixed(2);
                this.unit = 'm/h';
              }
            }
          ]
        : [
            {
              title: 'TUG Time',
              icon: FaHourglassHalf,
              extract: function () {
                this.value = stats.recordingTime.toFixed(1);
                this.unit = 's';
              }
            },
            {
              title: 'Walking',
              icon: FaPercentage,
              extract: function () {
                this.value = ((stats.duration / stats.recordingTime) * 100).toFixed(0);
                this.unit = '%';
              }
            }
          ];

    const allStats = [
      {
        title: 'Steps',
        icon: FaShoePrints,
        extract: function () {
          this.value = stats.steps;
          this.unit = '';
        }
      },
      {
        title: 'Distance',
        icon: FaRoute,
        extract: function () {
          const ds = makeDistanceString(stats.distance);
          this.value = ds.value;
          this.unit = ds.unit;
        }
      },
      {
        title: 'Duration',
        icon: FaHourglassHalf,
        extract: function () {
          const ds = makeTimeString(stats.duration);
          this.value = ds.value;
          this.unit = ds.unit;
        }
      },
      ...activitySpecfifcStats,
      {
        title: 'Cadence',
        icon: FaStopwatch,
        extract: function () {
          this.value = stats.cadence.toFixed(0);
          this.unit = 'SPM';
        }
      },
      {
        title: 'Gait speed',
        icon: FaTachometerAlt,
        extract: function () {
          this.value = stats.velocity.toFixed(2);
          this.unit = 'm/s';
        }
      }
    ];

    allStats.forEach((st) => {
      st.extract();
    });

    return allStats;
  };

  const summaryStatsRanges = {
    Cadence: getReferenceRange('cadence', patientData.sex, patientData.age).map((x) =>
      x.toFixed(0)
    ),
    'Gait speed': getReferenceRange('gait velocity', patientData.sex, patientData.age).map((x) =>
      x.toFixed(2)
    )
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', width: '100%', position: 'absolute', top: 2 }}>
        <Grid container>
          <Grid item xs={6} alignItems="start" justify="flex-start" sx={{ display: 'flex' }}>
            <Box>
              <MTooltipIconButton
                id="mark-for-comparison-button"
                buttonValue={isMarked ? 'bookmarksStarFill' : 'bookmarksStar'}
                title={isMarked ? 'Unmark' : 'Mark'}
                buttonType="tooltipIconButton"
                onClickEvent={markForComparisonClick}
              />
            </Box>
            <Box>
              <MTooltipIconButton
                id="download-report-button"
                buttonValue="filledDownload"
                title="Download Report"
                buttonType="tooltipIconButton"
                onClickEvent={downloadPdfClick}
              />
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="end" justifyContent="flex-end" sx={{ display: 'flex' }}>
            <Box>
              <MTooltipIconButton
                id="go-back-to-patient-page-button"
                buttonValue="arrowFromButton"
                title="Go to Patient Page"
                buttonType="tooltipIconButton"
                onClickEvent={backToPatientClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: 'scroll',
          height: '100%',
          p: 1
        }}
      >
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Grid container justifyContent="center" align="center">
            {generalDetails().map((detail) => {
              return (
                <Grid item xs key={uuidv4()}>
                  <MStatisticsCard icon={detail.icon} title={detail.title}>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      {detail.title === 'Patient' ? (
                        <Fragment>
                          <Typography
                            align="center"
                            variant="p"
                            color="primary"
                            sx={{ fontSize: 14, lineHeight: 1.1 }}
                          >
                            {detail.value.split(' ')[0]} <br />
                            {detail.value.split(' ')[1]}
                          </Typography>
                          <Typography color="primary" variant='caption' sx={{fontSize: 9}}>
                            {`${patientData.age} y/o | ${patientData.weight}kg | ${patientData.height}cm`}
                          </Typography>
                        </Fragment>
                      ) : (
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            align="center"
                            variant="p"
                            color="primary"
                            sx={{ fontSize: 14, lineHeight: 1.1 }}
                          >
                            {detail.value}
                          </Typography>
                          {analysisResults.vibrationType && detail.title === 'Type' && (
                            <Fragment>
                              <Typography
                                align="center"
                                variant="p"
                                color="primary"
                                sx={{
                                  fontSize: 14,
                                  marginLeft: 0.5,
                                  lineHeight: 1.1
                                }}
                              >
                                +
                              </Typography>
                              <Tooltip title={analysisResults.vibrationType}>
                                <Box>
                                  <MdVibration
                                    style={{
                                      marginLeft: 5,
                                      fontSize: 18
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Fragment>
                          )}
                        </Box>
                      )}
                    </Box>
                  </MStatisticsCard>
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            {statByRow().map((gr) => (
              <Grid container align="center" justifyContent="center" key={uuidv4()}>
                {formatSummaryStats(analysisResults.totalStats, patientData.height)
                  .slice(gr * ITEMS_PER_ROW, (gr + 1) * ITEMS_PER_ROW)
                  .map((st) => {
                    return (
                      <Grid item xs key={uuidv4()}>
                        <MStatisticsCard icon={st.icon} title={st.title} summaryStats>
                          <Typography
                            align="center"
                            color="secondary"
                            sx={{ fontSize: 20, lineHeight: 1.1 }}
                          >
                            {st.value}
                            <MUnit unit={st.unit} />
                          </Typography>
                          {['Cadence', 'Gait speed'].includes(st.title) && (
                            <Tooltip title="Reference Range">
                              <Box>
                                <Typography
                                  align="center"
                                  variant="caption"
                                  sx={{
                                    fontSize: 10,
                                    color: colors['lightGrey']
                                  }}
                                >
                                  <big>—[</big>
                                  <Typography
                                    align="center"
                                    variant="caption"
                                    sx={{
                                      fontSize: 10,
                                      color: theme.palette.primary.main
                                    }}
                                  >
                                    {summaryStatsRanges[st.title][0]} -{' '}
                                    {summaryStatsRanges[st.title][1]}
                                  </Typography>
                                  <big>]—</big>
                                </Typography>
                              </Box>
                            </Tooltip>
                          )}
                        </MStatisticsCard>
                      </Grid>
                    );
                  })}
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <MCommentField apiToken={apiToken} title="Assessment Comments" id="AssessmentComment" />
          </Box>
        </Stack>
      </Box>
    </Fragment>
  );
};

MAssessmentDetails.propTypes = {
  analysisResults: PropTypes.object
};
