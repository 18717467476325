import Pryv from 'pryv';

import { allActivityTypes, gaitActivityTypes } from '../definitions/activityTypes.js';
import environments from '../definitions/environments.json'
import { trendTypes } from '../definitions/statTypes.js';

const unixFuture = ~~(+new Date() / 1000) + 60 * 60 * 24;
const unixZero = 0;

/**
 * Fetch Data
 * @param {String} apiEndpoint
 * @param {Object} apiCall using pryv's call
 * @return {Object} containing value and unit as strings.
 */
export function pryvApiCall(apiEndpoint, apiCall) {
  const connection = new Pryv.Connection(apiEndpoint);
  return connection.api(apiCall);
}

export const doctorDataApiCall = [
  {
    method: 'events.get',
    params: {
      fromTime: unixZero,
      toTime: unixFuture,
      streams: ['.email', '.affiliation', 'profile']
    }
  },
  {
    method: 'events.get',
    params: {
      fromTime: unixZero,
      toTime: unixFuture,
      streams: ['patient-accesses']
    }
  }
];

export const deletePatientAccess = (id) => {
  const call = [
    {
      method: 'events.delete',
      params: {
        id: id
      }
    }
  ];
  return call;
};

export const generalPatientDataApiCall = [
  {
    method: 'events.get',
    params: {
      fromTime: unixZero,
      toTime: unixFuture,
      streams: ['profile']
    }
  }
];

export const allActivitiesMetadataCall = [
  {
    method: 'events.get',
    params: {
      fromTime: unixZero,
      toTime: unixFuture,
      streams: process.env.REACT_APP_CERTIFICATION === 'certified' ? {
        any: ['certified'],
        not: ['not-certified'],
      } : [
        'certified',
        'non-certified',
      ],
      types: ['activity/magnes-v2', 'activity/magnes-v1']
    }
  }
];

export const patientStatsCall = [
  {
    method: 'callBatch',
    params: [
      {
        method: 'events.get',
        params: {
          limit: 8,
          streams: {
            any: ['overall-stats'],
            all: [process.env.REACT_APP_CERTIFICATION === 'certified' ? 'certified': 'non-certified'],
            not: allActivityTypes.map( (at) => at.id)
          }
        }
      },
      {
        method: 'events.get',
        params: {
          streams: ['macro-events']
        }
      },
      {
        method: 'callBatch',
        params: gaitActivityTypes
          .map((at) =>
            trendTypes.map((st) => {
              return {
                method: 'events.get',
                params: {
                  limit: 1,
                  streams: {
                    any: [at.id],
                    all: [st, process.env.REACT_APP_CERTIFICATION === 'certified' ? 'certified': 'non-certified'],
                  }
                }
              };
            })
          )
          .flat()
      }
    ]
  }
]

export const assessmentCall = (id) => {
  const call = [
    {
      method: 'events.get',
      params: {
        streams: {
          all: [`activity-${id}`],
          any: ['result-data']
        },
      }
    }
  ];
  return call;
};


export const prevPostResultsCall = (toTime, limit = 10) => {
  const types = allActivityTypes.filter((at) => at.id == 'posturography')[0].resultType
  return [
    {
      method: 'events.get',
      params: {
        limit: limit,
        fromTime: unixZero,
        toTime: toTime,
        streams: ['result-data'],
        types: types
      }
    }
  ]
}

export const pdfReportsCall = (id) => {
  const call = [
    {
      method: 'events.get',
      params: {
        streams: {
          all: [`activity-${id}`],
          any: ['reports']
        }
      }
    }
  ];
  return call;
};

export const deleteEventCall = (id) => {
  const call = [
    {
      method: 'events.delete',
      params: {
        id: id
      }
    }
  ];
  return call;
};

export const eventsFromStreams = (streams) => {
  return [
    {
      method: 'events.get',
      params: {
        fromTime: unixZero,
        toTime: unixFuture,
        streams: streams
      }
    }
  ];
};

export const updateEventCall = (id, content) => {
  const call = [
    {
      method: 'events.update',
      params: {
        id: id,
        update: {
          content: content
        }
      }
    }
  ];
  return call;
};

export const createEventCall = (streamIds, type, content, ts) => {
  const call = [
    {
      method: 'events.create',
      params: {
        streamIds: streamIds,
        type: type,
        content: content,
        time: ts
      }
    }
  ];
  return call;
};

export const getRegisteredAffiliations = (instance) => {
  if ((!instance || !environments[instance])) {
    return undefined
  }

  const endpointURL = environments[instance].endpoints.affiliations_url
  return fetch(`${endpointURL}/affiliations.json`)
    .then( res => res.json())
    .catch((e) => {
      console.error(`Failed to fetch affiliations - ${e}`)
      return undefined
    })
}
