import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useTheme } from '@emotion/react';
import { Box, Divider, IconButton, Pagination, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { MHCycleGraph,MHRDurationHist, MHRHeelRaisePictogram, MHRRepetitionCountDoughnut, MHRSetDoughnut } from './MHRMiniPlots';
import { MHRSetResults } from './MHRSetResults';

const SIDE_LABEL = {
  "L": "Left",
  "R": "Right",
}

const FRACTIONS = [
  "raise_fraction",
  "hold_fraction",
]


export const MHRResults = ({ results }) => {
  const [currentIdx, setCurrentIdx] = React.useState(0);
  const [navigationDirection, setNavigationDirection] = React.useState(0);

  const theme = useTheme();

  const colorLeft=theme.palette.info.main
  const colorRight=theme.palette.secondary.main

  let allAngles = {
    L: [],
    R: [],
  }

  const allDurations = {
    L: [],
    R: [],
  }

  const allFractions = {}
  for (const side in SIDE_LABEL) {
    allFractions[side] = {}

    for (const fraction of FRACTIONS) {
      allFractions[side][fraction] = []
    }
  } 

  for (const set of results.sets) {
    for (const side of ["L", "R"]) {
      if (!set.raises[side]) {
        continue
      }
      allAngles[side].push(...set.raises[side].map(raise => raise.angle))
      allDurations[side].push(...set.raises[side].map(raise => raise.duration))

      for (const fraction of FRACTIONS) {
        allFractions[side][fraction].push(...set.raises[side].map(raise => raise[fraction]))
      }
    }
  }

  const hasLeft = allDurations.L.length
  const hasRight = allDurations.R.length

  if (!hasLeft && !hasRight) {
    return (
      <Box style={{display: 'flex', flexDirection: 'column', height: 'calc((100vh - 160px))', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <Typography align="center">
          Seems like you don&apos;t have any results... Oops.
        </Typography>
      </Box>
    )
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: 'calc((100vh - 160px))', width: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row', height: '40%', width: '100%', padding: 5}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', width: '15%', padding: 10}}>
          <Typography align='center' variant='overline'>
            Sets
          </Typography>
          <MHRSetDoughnut sets={results.sets} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', width: '15%', padding: 10}}>
          <Typography align="center" variant='overline'>
            Repetitions
          </Typography>
          <MHRRepetitionCountDoughnut sets={results.sets} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '10px', height: '100%'}}>
          <div>
            {''} <br/>
          </div>
          <Typography
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: 'center',
              whiteSpace: 'nowrap',
              width: '20px',
              height: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'gray'
            }}
          >
            Left
          </Typography>
          <Typography
            sx={{
              transform: 'rotate(-90deg)',
              transformOrigin: 'center',
              whiteSpace: 'nowrap',
              width: '20px',
              height: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'gray'
            }}
          >
            Right
          </Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', width: '25%', padding: 10 }}>
          <Typography align="center" variant='overline'>
            Angle
          </Typography>
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
            {hasLeft && 
              <MHRHeelRaisePictogram values={allAngles.L} color={colorLeft}/>
            }
          </div>
          <Divider sx={{width: "100%" }} />
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
          {hasRight && 
              <MHRHeelRaisePictogram values={allAngles.R} color={colorRight}/>
            }
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', width: '20%', padding: 10}}>
          <Typography align="center" variant='overline'>
            Duration
          </Typography>
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
            {hasLeft && 
              <MHRDurationHist values={allDurations.L} color={colorLeft}/>
            }
          </div>
          <Divider sx={{width: "100%" }} />
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
            {hasRight && 
              <MHRDurationHist values={allDurations.R} color={colorRight}/>
}
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', height: '100%', width: '25%', padding: 10}}>
          <Typography align="center" variant='overline'>
            Cycle Break Up
          </Typography>
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
            {hasLeft && 
              <MHCycleGraph durations={allDurations.L} fractions={allFractions.L}/>
            }
          </div>
          <Divider sx={{width: "100%" }} />
          <div style={{ width: '100%', height: 'calc((100% - 33px) / 2)'}}>
            {hasRight &&
              <MHCycleGraph durations={allDurations.R} fractions={allFractions.R}/>
            }
          </div>
        </div>
      </div>
      <Divider orientation='horizontal' />
      <div style={{display: 'flex', flexDirection: 'column', height: '60%', width: '100%', justifyContent: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', height: 'calc(100% - 0px)', width: '100%', justifyContent: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: 40, justifyContent: 'center', alignContent: 'center' }}>
            <Tooltip title="Previous Set">
              <IconButton
                id="back-one-assessment-button"
                onClick={() => {
                  setCurrentIdx(currentIdx - 1)
                  setNavigationDirection(-1)
                }}
                disabled={currentIdx === 0}
                sx={{ color: theme.palette.primary.main }}
              >
                <FaAngleLeft style={{ fontSize: 25 }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: 'calc(100% - 80px)', justifyContent: 'center', alignContent: 'center' }}>
            <MHRSetResults idx={currentIdx} set={results.sets[currentIdx]} navigationDirection={navigationDirection}/>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: 40, justifyContent: 'center', alignContent: 'center'}}>
            <Tooltip title="Next Set">
              <IconButton
                id="back-one-assessment-button"
                onClick={() => {
                  setCurrentIdx(currentIdx + 1)
                  setNavigationDirection(1)
                }}
                disabled={currentIdx + 1 === results.sets.length}
                sx={{ color: theme.palette.primary.main }}
              >
                <FaAngleRight style={{ fontSize: 25 }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, width: '100%'}}>
          <Pagination
            hidePrevButton
            hideNextButton
            count={results.sets.length}
            page={currentIdx + 1}
            onChange={(_, pageNumber) => {
              setNavigationDirection(pageNumber - currentIdx)
              setCurrentIdx(pageNumber - 1)
            }} />
        </div>
      </div>
    </div>
  )
}


MHRResults.propTypes = {
  results: PropTypes.object,
};
