import { useState } from "react"
import { Box, ButtonGroup } from "@mui/material"
import { PropTypes } from 'prop-types';

import { MTooltipIconButton } from "../../../components/ui/MTooltipIconButton"

import { MTTSetResultGraph } from "./MTTMiniplots";

export const MTTSetResults = ({overallResults}) => {
  const [view, setView] = useState('duration')
  
  const handleViewChange = (e) => {
    const value = e.currentTarget.value
    
    if (value === view) {
      return
    }
    
    setView(value)
  }

  const sharedChartOptions = {
    maxY: Math.max(...[].concat(...overallResults.L[view]).concat(...overallResults.R[view])),
    maxX: Math.max(...[].concat(...overallResults.L[view].map(res => res.length)).concat(...overallResults.R[view].map(res => res.length))),
  }
  
  return (
      <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', height: 'calc(100% - 0px)', width: '100%', justifyContent: 'center', p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: 'calc(100% - 10px)', width: '50%', justifyContent: 'center', p: 2 }}>
            <MTTSetResultGraph param={view} datasets={overallResults.L[view]} side="Left" options={sharedChartOptions}/>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: 'calc(100% - 10px)', width: '50%', justifyContent: 'center', p: 2 }}>
          <MTTSetResultGraph param={view} datasets={overallResults.R[view]} side="Right" options={sharedChartOptions}/>
          </Box>
        </Box>
        <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', p: 1 }}>
          <ButtonGroup color="primary">
              <MTooltipIconButton
                buttonValue="duration"
                title="Show Tap Durations"
                value={'duration'}
                onClickEvent={handleViewChange}
                variant={view === 'duration'} />
              <MTooltipIconButton
                buttonValue="intensity"
                title="Show Tap Intensities"
                value={'intensity'}
                onClickEvent={handleViewChange}
                variant={view === 'intensity'} />
              <MTooltipIconButton
                buttonValue="amplitude"
                title="Show Tap Amplitudes"
                value={'amplitude'}
                onClickEvent={handleViewChange}
                variant={view === 'amplitude'} />
              <MTooltipIconButton
                buttonValue="f"
                title="Show Frequencies"
                value={'frequency'}
                onClickEvent={handleViewChange}
                variant={view === 'frequency'}
                buttonStyle={{textTransform: 'lowercase'}}
                />
          </ButtonGroup>
      </Box>
    </Box>
  )
}

MTTSetResults.propTypes = {
  overallResults: PropTypes.object,
}
